import React from 'react';

import { Router, Switch, Route} from 'react-router-dom';
import MainPage from './MainPage';
import ApiDocsPortal from './ApiDocsPortal';
import ApiDocs from './components/apiDocsComponents/ApiDocs';
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";
import PrivateRoute from "./components/PrivateRoute";
import MarkdownDocumentation from './components/userManual/MarkdownDocumentation';

export default function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <div className="custom-spinner"></div>;
  } 

  return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route path="/api-docs" component={ApiDocsPortal} />
          <PrivateRoute path="/api-documentation" component={ApiDocs} />
          <PrivateRoute path="/documentation" component={MarkdownDocumentation} />
        </Switch>      
      </Router>
  );
}