import React from 'react';

import ApiPortalButtonComponent from './ApiPortalButtonComponent';
import {buttonFactory} from './ApiDocsNavigationFactory';

interface Props {
  button: object
}

export default function ApiPortNavigation<Props>() {
  return(
    <>
      {buttonFactory.map((button, index)=> (
        <ApiPortalButtonComponent key={index} button={button} />
      ))}
    </>
  )
}