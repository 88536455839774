import React from 'react';
import { Link } from 'react-router-dom';

export default function ApiPortalButtonComponent({button}) {
  return(
    <div className="button-container">
      <img src={button.image} />
      <h1 className="title h4">{button.title}</h1>
      <p>{button.paragraph}</p>
      <div className="subtext">
        <Link className="button" to={button.path} >{button.learnMoreBtn}</Link>
      </div>
    </div>
  )
}