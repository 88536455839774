import React, { useState } from 'react';
import jump from '../../../node_modules/jump.js/dist/jump';

interface Props {
  link: any
}

const StickyNav: React.FC<Props> = ({link}) => {

  const [active, setActive] = useState(false);
  return(
  <>
    <li className={!active ? "link-btn": "link-btn active"} onClick={()=> (
      setActive(true),
      jump(link.target, {
      duration: 1000,
      offset: -150,
      }
    ))}>{link.title}</li>
  </>
  )
}

export default StickyNav;