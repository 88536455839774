import React, {useState, useEffect, useRef} from 'react';
import { RedocStandalone } from 'redoc';
import Header from '../Header';
import { Link } from 'react-router-dom';

import {buttonFactory} from './ApiDocsNavigationFactory';

export default function ApiDocs() {

  const [buttonState, changeButtonState] = useState({
    activeButton: {},
    buttons: [{id: 1}, {id:2}, {id: 3}]
  })
  let link = "";
  let initialButtonIndex = 0;

  switch (window.location.pathname) {
    case "/api-documentation/api":
      link = "https://app.livedooh.com/api/openapi"
      initialButtonIndex = 0;
      break;
    case "/api-documentation/proof-of-play":
      link = "https://pop.livedooh.com/api/documentation/v1/open-api.json"
      initialButtonIndex = 1;
      break;
    case "/api-documentation/aggregated-reports":
      link = "https://pop-aggregated.livedooh.com/api/documentation/v1/open-api.json"
      initialButtonIndex = 2;
      break;
    default: 
      link = "https://app.livedooh.com/api/openapi"
      initialButtonIndex = 0;
  }

  function toggleActiveStyles(index) {
    if (buttonState.buttons[index] === buttonState.activeButton) {
      return "top-nav-btn active"
    } else {
      return "top-nav-btn"
    }
  }

  function toggleActive(index) {
    changeButtonState({...buttonState, activeButton: buttonState.buttons[index]})
  }

  useEffect(()=> {
    changeButtonState({...buttonState, activeButton: buttonState.buttons[initialButtonIndex]})
    toggleActiveStyles(initialButtonIndex)
    console.log('called: ', initialButtonIndex);
  }, []);

  return(
    <div>
      <Header scroll={true} onPortal={true} sidebarVisibility={false} onChildClick={null}/>
      <div className="top-nav-container">
        <ul className="row">
          {buttonFactory.map((button, index)=> (
            <li key={index} className={toggleActiveStyles(index)} onClick={()=> {
              toggleActive(index)
              }}>
              <Link to={button.path} >{button.title}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="api-docs-container">
        <RedocStandalone specUrl={link} options={{
            scrollYOffset : 150,
            theme: {
              rightPanel: {
                backgroundColor: '#E0E0E0'
              },
              typography: {
                fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
                headings: {
                  fontFamily: 'Poppins',
                }
              },
              codeBlock: {
                backgroundColor: '#212121',
              },
            }
          }}
        />
      </div>
    </div>
  )
}