import image1 from '../../assests/images/apiPortalImages/media-owner-benefit-flat-3-1.svg';
import image2 from '../../assests/images/apiPortalImages/media-owner-benefit-flat-4-1.svg';
import image3 from '../../assests/images/apiPortalImages/media-owner-benefit-flat-2-1.svg';

import text from '../../locale/LocaleEn';

export const buttonFactory = [
  {
    // API Docs

    title: text.apiDocsPortal.apiDocs.title,
    paragraph: text.apiDocsPortal.apiDocs.text,
    image: image1,
    learnMoreBtn: text.apiDocsPortal.learnMoreBtn,
    link: "https://cors-anywhere.herokuapp.com/https://app.livedooh.com/api/openapi",
    path: "/api-documentation/api"

  },

  {
    // Proof of play docs

    title: text.apiDocsPortal.proofOfPlay.title,
    paragraph: text.apiDocsPortal.proofOfPlay.text,
    image: image2,
    learnMoreBtn: text.apiDocsPortal.learnMoreBtn,
    link: "https://cors-anywhere.herokuapp.com/https://pop.livedooh.com/api/documentation/v1/open-api.json",
    path: "/api-documentation/proof-of-play"

  },

  {
    // Aggregated reports

    title: text.apiDocsPortal.aggregatedReports.title,
    paragraph: text.apiDocsPortal.aggregatedReports.text,
    image: image3,
    learnMoreBtn: text.apiDocsPortal.learnMoreBtn,
    link: "https://cors-anywhere.herokuapp.com/https://pop-aggregated.livedooh.com/api/documentation/v1/open-api.json",
    path: "/api-documentation/aggregated-reports"
  }

]